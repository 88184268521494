import "./styles.css";

const Menu = () => {
  return (
    <nav className="nav-menu">
      <span>Luiz Filipe S. J. Silva</span>
      <ul>
        <li>
          <a href="#sobre">Quem Sou Eu? </a>
        </li>
        <li>Onde ja Trabalhei</li>
        <li>Opção3</li>
      </ul>
    </nav>
  );
};

export { Menu };
